var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("stepNav", {
        attrs: {
          name: _vm.$t("roof.roof_security"),
          validStep: _vm.isStepComplete(
            [
              "roofSafetyLengths",
              "roofBridgeLengths",
              "roofRailLengths",
              "roofHatchSafetyAmount",
            ],
            "single"
          ),
        },
      }),
      _c("div", { staticClass: "product-groups" }, [
        _c(
          "div",
          { staticClass: "product-group" },
          [
            _c("div", { staticClass: "product-group-image" }, [
              _c("img", {
                attrs: {
                  src: require("@/assets/snowSafety/172420.png"),
                  alt: _vm.snowsafety.name,
                  width: "150px",
                },
              }),
            ]),
            _c(
              "formField",
              {
                attrs: {
                  label: _vm.$t("roof.snow_safety_lengths"),
                  horizontal: false,
                  message:
                    _vm.validationError.field === "snowsafetyLengths"
                      ? _vm.validationError.text
                      : null,
                },
              },
              [
                _c("tagInput", {
                  attrs: {
                    "create-tag": _vm.addTag,
                    beforeAdding: _vm.validateTag,
                    "confirm-keys": ["Enter", "Tab", "+"],
                    "on-paste-separators": ["+"],
                    "allow-duplicates": true,
                    placeholder:
                      _vm.snowsafetyLengths.length === 0
                        ? _vm.$t("roof.placeholder.input_meter")
                        : null,
                  },
                  on: {
                    validationError: function ($event) {
                      _vm.validationError = {
                        field: "snowsafetyLengths",
                        text: $event,
                      }
                    },
                  },
                  model: {
                    value: _vm.snowsafetyLengths,
                    callback: function ($$v) {
                      _vm.snowsafetyLengths = $$v
                    },
                    expression: "snowsafetyLengths",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "product-group" },
          [
            _c("div", { staticClass: "product-group-image" }, [
              _c("img", {
                attrs: {
                  src: require("@/assets/roofBridge/170220.png"),
                  alt: _vm.roofBridge.name,
                  width: "150px",
                },
              }),
            ]),
            _c(
              "formField",
              {
                attrs: {
                  label: _vm.$t("roof.roof_bridge_lengths"),
                  horizontal: false,
                  message:
                    _vm.validationError.field === "roofBridgeLengths"
                      ? _vm.validationError.text
                      : null,
                },
              },
              [
                _c("tagInput", {
                  attrs: {
                    "create-tag": _vm.addTag,
                    beforeAdding: _vm.validateTag,
                    placeholder:
                      _vm.roofBridgeLengths.length === 0
                        ? _vm.$t("roof.placeholder.input_meter")
                        : null,
                  },
                  on: {
                    validationError: function ($event) {
                      _vm.validationError = {
                        field: "roofBridgeLengths",
                        text: $event,
                      }
                    },
                  },
                  model: {
                    value: _vm.roofBridgeLengths,
                    callback: function ($$v) {
                      _vm.roofBridgeLengths = $$v
                    },
                    expression: "roofBridgeLengths",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "product-group" },
          [
            _c("div", { staticClass: "product-group-image" }, [
              _c("img", {
                attrs: {
                  src: require("@/assets/roofRail/172620.png"),
                  alt: _vm.roofRail.name,
                  width: "150px",
                },
              }),
            ]),
            _c(
              "formField",
              {
                attrs: {
                  label: _vm.$t("roof.roof_rail_lengths"),
                  horizontal: false,
                  message:
                    _vm.validationError.field === "roofRailLengths"
                      ? _vm.validationError.text
                      : null,
                },
              },
              [
                _c("tagInput", {
                  attrs: {
                    "create-tag": _vm.addTag,
                    beforeAdding: _vm.validateTag,
                    placeholder:
                      _vm.roofRailLengths.length === 0
                        ? _vm.$t("roof.placeholder.input_meter")
                        : null,
                  },
                  on: {
                    validationError: function ($event) {
                      _vm.validationError = {
                        field: "roofRailLengths",
                        text: $event,
                      }
                    },
                  },
                  model: {
                    value: _vm.roofRailLengths,
                    callback: function ($$v) {
                      _vm.roofRailLengths = $$v
                    },
                    expression: "roofRailLengths",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "product-group" },
          [
            _c("div", { staticClass: "product-group-image" }, [
              _c("img", {
                attrs: {
                  src: require("@/assets/roofHatchSafety/170820.png"),
                  alt: _vm.roofHatch.name,
                },
              }),
            ]),
            _c(
              "formField",
              {
                attrs: {
                  label: _vm.$t("roof.roof_hatch_safety_amount"),
                  horizontal: false,
                },
              },
              [
                _c("b-input", {
                  attrs: {
                    type: "number",
                    min: "0",
                    max: "100",
                    step: "1",
                    controls: false,
                  },
                  model: {
                    value: _vm.roofHatchSafety,
                    callback: function ($$v) {
                      _vm.roofHatchSafety = _vm._n($$v)
                    },
                    expression: "roofHatchSafety",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }