<template>
  <div>
    <!-- <div class="columns is-centered">
      <div class="column is-full">
        <p class="help-text"></p>
      </div>
    </div> -->
    <stepNav :name="$t('roof.roof_security')" :validStep="isStepComplete(['roofSafetyLengths', 'roofBridgeLengths', 'roofRailLengths', 'roofHatchSafetyAmount'], 'single')"/>
    <div class="product-groups">
      <div class="product-group">
        <div class="product-group-image">
          <img v-bind:src="require('@/assets/snowSafety/172420.png')" v-bind:alt="snowsafety.name" :width="'150px'">
        </div>
        <formField
          :label="$t('roof.snow_safety_lengths')"
          :horizontal="false"
          :message="validationError.field === 'snowsafetyLengths' ? validationError.text : null"
        >
          <tagInput
            v-model="snowsafetyLengths"
            :create-tag="addTag"
            :beforeAdding="validateTag"
            :confirm-keys="['Enter','Tab','+']"
            :on-paste-separators="['+']"
            :allow-duplicates="true"
            :placeholder="snowsafetyLengths.length === 0 ? $t('roof.placeholder.input_meter') : null"
            @validationError="validationError = {field: 'snowsafetyLengths', text: $event}"
          ></tagInput>
        </formField>
      </div>
      <div class="product-group">
        <div class="product-group-image">
          <img v-bind:src="require('@/assets/roofBridge/170220.png')" v-bind:alt="roofBridge.name" :width="'150px'">
        </div>
        <formField
          :label="$t('roof.roof_bridge_lengths')"
          :horizontal="false"
          :message="validationError.field === 'roofBridgeLengths' ? validationError.text : null"
        >
          <tagInput
            v-model="roofBridgeLengths"
            :create-tag="addTag"
            :beforeAdding="validateTag"
            :placeholder="roofBridgeLengths.length === 0 ? $t('roof.placeholder.input_meter') : null"
            @validationError="validationError = {field: 'roofBridgeLengths', text: $event}"
          ></tagInput>
        </formField>
      </div>
      <div class="product-group">
        <div class="product-group-image">
          <img v-bind:src="require('@/assets/roofRail/172620.png')" v-bind:alt="roofRail.name" :width="'150px'">
        </div>
        <formField
          :label="$t('roof.roof_rail_lengths')"
          :horizontal="false"
          :message="validationError.field === 'roofRailLengths' ? validationError.text : null"
        >
          <tagInput
            v-model="roofRailLengths"
            :create-tag="addTag"
            :beforeAdding="validateTag"
            :placeholder="roofRailLengths.length === 0 ? $t('roof.placeholder.input_meter') : null"
            @validationError="validationError = {field: 'roofRailLengths', text: $event}"
          ></tagInput>
        </formField>
      </div>
      <div class="product-group">
        <div class="product-group-image">
          <img v-bind:src="require('@/assets/roofHatchSafety/170820.png')" v-bind:alt="roofHatch.name">
        </div>
        <formField
          :label="$t('roof.roof_hatch_safety_amount')"
          :horizontal="false"
        >
          <b-input
            type="number"
            min="0"
            max="100"
            step="1"
            :controls="false"
            v-model.number="roofHatchSafety"
          ></b-input>
        </formField>
        <!-- <b-field label="Antal skyddsräcken (1 pkt / taklucka)" :horizontal="false">
          <b-input
            type="number"
            min="0"
            max="100"
            step="1"
            :controls="false"
            v-model.number="roofHatchSafety"
          ></b-input>
      </b-field> -->
      </div>
    </div>
  </div>
</template>

<script>
import stepValidation from '../../mixins/stepValidation'

export default {
  name: 'safeProducts',
  components: {
    stepNav: () => import("../stepNav"),
    formField: () => import("../formField"),
    tagInput: () => import("../tagInput"),
  },
  mixins: [
    stepValidation
  ],
  data: function () {
    return {
      validationError: {}
    }
  },
  computed: {
    snowsafetyLengths: {
      get() {return this.$store.getters.getSelectedValue('roofSafetyLengths')},
      set(value) {
        if (value.length > 0) {
          this.$store.dispatch('updateRequierdFields', {step: 'alternatives', value: ['accessoryColor', 'roofTilt', 'snowZone', 'roofLength']})
        } else {
          this.$store.dispatch('updateRequierdFields', {step: 'alternatives', value: ['accessoryColor']})
        }
        this.$store.dispatch('updateValue', {name: 'roofSafetyLengths', value: value})
      }
    },
    snowsafety () {
      let products = this.$store.getters.getProducts
      let selections = this.$store.getters.getSelections
      let product = products.find(p => p.type === 'snowSafety')
      let variant = product.variants.find(v => v.color == selections.accessoryColor.value)
      let fullProduct = {...product, ...variant}
      delete fullProduct.variants;
      return fullProduct
    },
    roofBridgeLengths: {
      get() {return this.$store.getters.getSelectedValue('roofBridgeLengths')},
      set(value) {this.$store.dispatch('updateValue', {name: 'roofBridgeLengths', value: value})}
    },
    roofBridge () {
      let products = this.$store.getters.getProducts
      let selections = this.$store.getters.getSelections
      let product = products.find(p => p.type === 'roofBridge')
      let variant = product.variants.find(v => v.color == selections.accessoryColor.value)
      let fullProduct = {...product, ...variant}
      delete fullProduct.variants;
      return fullProduct
    },
    roofHatchSafety: {
      get() {return this.$store.getters.getSelectedValue('roofHatchSafetyAmount')},
      set(value) {this.$store.dispatch('updateValue', {name: 'roofHatchSafetyAmount', value: value})}
    },
    roofHatch () {
      let products = this.$store.getters.getProducts
      let selections = this.$store.getters.getSelections
      let product = products.find(p => p.type === 'roofHatchSafety')
      let variant = product.variants.find(v => v.color == selections.accessoryColor.value)
      let fullProduct = {...product, ...variant}
      delete fullProduct.variants;
      return fullProduct
    },
    roofRailLengths: {
      get() {return this.$store.getters.getSelectedValue('roofRailLengths')},
      set(value) {this.$store.dispatch('updateValue', {name: 'roofRailLengths', value: value})}
    },
    roofRail () {
      let products = this.$store.getters.getProducts
      let selections = this.$store.getters.getSelections
      let product = products.find(p => p.type === 'roofRail')
      let variant = product.variants.find(v => v.color == selections.accessoryColor.value)
      let fullProduct = {...product, ...variant}
      delete fullProduct.variants;
      return fullProduct
    },
  },
  methods: {
    goToPrevStep() {
      this.$store.dispatch('previousStep')
    },
    goToNextStep() {
      this.$store.dispatch('nextStep')
    },
    markFormatter(value) {
      return {
          label: `${value}`
      }
    },
    validateTag(tag) {
      tag = tag.replace(',', '.')
      if (tag[tag.length-1] === ",") {tag = tag.slice(0,-1)}
      return !isNaN(Number(tag))
    },
    addTag(tag) {
      let tagReplacedComma = tag.replace(",", ".")
      let tagNumber = Number(tagReplacedComma)
      return tagNumber
    },
  },
}
</script>

<style>
.help-text {
  text-align: left
}
.taginput {
  padding-top: 0px !important;
  padding-bottom: 0px;
}
.product-groups{
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 100%;
}
.product-group{
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin: 10px 5px;
  width: 100%;
  max-width: 500px;
}
.product-group .tag-input{
  /* max-width: 300px; */
  flex: 4 1 75%;
}
.product-group .field{
  /* max-width: 300px; */
  flex: 4 1 75%;
}
.product-group .formField{
  /* max-width: 300px; */
  flex: 4 1 75%;
}
.product-group-image{
  /* max-height: 150px; */
  margin-right: 20px;
  flex: 1 1 25%;
}
.product-group img{
  max-width: 100%;
  max-height: 100%;
}
</style>
